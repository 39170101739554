import React from 'react';
import styles from './arrow-link.module.scss';
// @ts-ignore
import {HashLink as Link} from 'react-router-hash-link';

interface Props {
    toSection: string;

}

function ArrowLink(props: Props) {
    return (
        <Link smooth to={props.toSection} className={styles.arrowDown} aria-label={"Scroll to next section"}>
            <span className={styles.arrowInner}/>
        </Link>
    );
}

export default ArrowLink;