import React from 'react';
import styles from './stars-animation.module.scss';


function StarsAnimation() {

    return (
        <div className={styles.starsContainer}>
            <div className={styles.stars} />
            <div className={styles.stars2} />
            <div className={styles.stars3} />
            <div className={styles.stars4} />
        </div>
    );
}

export default StarsAnimation;