import React from 'react';
import styles from './burger.module.scss';
import {ApplicationState} from '../../../redux/reducers';
import {connect} from "react-redux";
import {actionOpenMenu, actionCloseMenu} from '../../../redux/actions/actionsNavmenu';

interface Props {
    isOpen: boolean;
    openMenu: () => void;
    closeMenu: () => void;
}

function Burger({isOpen, closeMenu, openMenu}: Props) {
    return (
        <button
            className={`${styles.hamburger} ` + (isOpen ? `${styles.isActive}` : ``)}
            onClick={() => isOpen ? closeMenu() : openMenu()}
            aria-label={'open menu'}
        >
            <div className={styles.hamburgerInner}/>
        </button>
    );
}

export default connect(
    (state: ApplicationState) => ({
        isOpen: state.navmenu.isOpen
    }),
    {
        openMenu: actionOpenMenu,
        closeMenu: actionCloseMenu
    }
)(Burger);
