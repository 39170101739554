import {Document} from './Document';

export class Contact extends Document {
    public email: string;
    public phone: string;
    public address: string;

    constructor(doc: any) {
        super(doc);
        this.email = doc.data.email;
        this.phone = doc.data.phone;
        this.address = doc.data.address;
    }
}
