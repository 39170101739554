import {useEffect, useState} from "react";
import IPrismicApiService from "../../api/services/IPrismicApiService";

export const useGqlPrismicApi = <Model>({apiService, query, ref}: { apiService: IPrismicApiService<Model>, query?:string, ref?:string}) => {

    const [data, setData] = useState<Model[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setData([]);
            try {
                const result = await apiService.index({query, ref});
                setData(result);
            } catch (error) {
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [query]);

    return [{ data, isLoading, isError }, ];

};
