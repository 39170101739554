import React, {useEffect} from 'react';
import styles from './case-page.module.scss';
import {useParams} from 'react-router-dom';
import useMetaTags from "../../hooks/useMetaTags";
import {useGqlCaseStudyByUidApi} from "../../hooks/useGqlCaseStudyByUidApi";
import Hero from "./hero/hero";
import ReviewSection from "./client-review/client-review";
import InfoSection from "./client-info/client-info";
import ChallengeDoneSection from "./challenge-done-section/challenge-done-section";
import SolutionSection from "./solution-section/solution-section";
import ImagesGallery from "./images-gallery/images-gallery";
import NextCase from "./next-case/next-case";


function CasePage({history}: any) {
    const {uid = ''} = useParams() as any;

    const {data: casestudy, isLoading, isError} = useGqlCaseStudyByUidApi(uid);
    const fetchState = {isLoading, isError};


    let {
        hero,
        challenge_section,
        client_info,
        client_review,
        done_section,
        image_gallery,
        solution_section,
        next_case
    } = casestudy || {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [uid]);

    useMetaTags(casestudy);

    if (!isLoading && !casestudy) {
        history.push('/404-page');
        return (<></>);
    }

    return (
        <div className={styles.casePage}>
            <Hero {...fetchState} content={hero}/>
            {client_info && <InfoSection {...fetchState} content={client_info}/>}
            {challenge_section &&
            <ChallengeDoneSection {...fetchState} content={challenge_section} title={"The challenge"}/>}
            {solution_section && <SolutionSection {...fetchState} content={solution_section}
                                                  tags={casestudy && casestudy.hasTags && casestudy.tags}/>}
            {image_gallery && <ImagesGallery {...fetchState} content={image_gallery}/>}
            {done_section && <ChallengeDoneSection {...fetchState} content={done_section} title={"The done"}/>}
            {client_review && <ReviewSection {...fetchState} content={client_review}/>}
            {next_case && <NextCase {...fetchState} content={next_case}/>}
        </div>
    );

}

export default CasePage;