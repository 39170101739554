import React from 'react';
import styles from './cases.module.scss';
import SkeletonAnnotation from "../../elements/annotation-block/skeleton-annotation/skeletonAnnotation";
import AnnotationBlock from "../../elements/annotation-block/annotationBlock";
import {Case} from "../../../../api/models/Case";

interface Props {
    isLoading: boolean;
    isError:boolean;
    cases: Case[] | null
}

const ELEMENTS_NUMBER = 4;

function Cases(props:Props) {
    return (
        <section className={styles.section}>
            <span>What we do</span>
            <h1 className={styles.title}>Cases</h1>
            <div className={styles.container}>
                {props.isLoading && Array(ELEMENTS_NUMBER)
                    .fill('', 0)
                    .map((item, index) =>
                        <div className={styles.annotation} key={index}>
                            <SkeletonAnnotation haveSubtitle={true} type={'case'}/>
                        </div>
                    )}
                {!props.isLoading && props.cases && props.cases.map((item, index) =>
                    <div className={styles.annotation} key={index}>
                        <AnnotationBlock
                            img={item.preview_image}
                            title={item.title}
                            subtitle={item.preview_text}
                            uid={item.uid}
                            type={item.type}
                        />
                    </div>
                )}
            </div>


        </section>
    );
}

export default Cases;

//@todo "Read more ..." link leading to articles/cases page?
