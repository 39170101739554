import DocumentService from './DocumentService';
import {Legal} from '../models/Legal';

export default class ArticleService extends DocumentService<Legal> {

    public async index(): Promise<Legal[]> {
        return super.index({queryBy: 'document.type', value: 'legal'});
    }

    protected makeModel(data: any) {
        return new Legal(data);
    }
}