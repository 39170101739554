import React from 'react';
import {Link, useLocation} from "react-router-dom";
import styles from './annotationBlock.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {imageUrlParamsHandler} from '../../../helpers/imageUrlParamsHandler';

interface AnnotationBlockProps {
    type: string,
    uid: string,
    img: string,
    title: string,
    subtitle?: string,
}

function AnnotationBlock(props: AnnotationBlockProps) {

    const READ_MORE_TEXT = props.type === 'case_study' ? "VIEW CASE STUDY" : "READ ARTICLE";
    const READ_MORE_URL = (props.type === 'case_study') ? `/cases/${props.uid}` : `/blog/${props.uid}`;
    const title = props.type === 'case_study' ? props.subtitle : props.title;
    const subtitle = props.type === 'case_study' ? props.title : '';

    const location = useLocation().pathname;

    return (
        <Link
            className={styles.container}
            to={READ_MORE_URL}
        >
            <div className={
                props.type === 'blog_article' && location === '/'
                  ? `${styles.imgContainer} ${styles.small}`
                  : `${styles.imgContainer}`
            }>
                <img src={imageUrlParamsHandler(props.img, {width: 640})} alt={props.title} className={styles.image}/>
            </div>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            <p className={styles.overflowEllipsis}>{title}</p>
            <div className={styles.btn}>
                {READ_MORE_TEXT}
                <span className={`${styles.dot} ${styles.arrowAnimation}`}/>
                <FontAwesomeIcon className={styles.arrow} icon={faArrowRight}/>
            </div>
        </Link>
    );
}

export default AnnotationBlock;
