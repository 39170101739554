import styles from './home.module.scss';
import React from 'react';
import Main from './main/main';
import AboutUs from './about-us/about-us';
import Reviews from './reviews/reviews';
import Cases from './cases/cases';
import Blog from './blog/blog';
import useMetaTags from "../../hooks/useMetaTags";
import {useHomepageContentApi} from "../../hooks/useHomepageContentApi";

function Home() {
    let [{data: content, isLoading, isError}] = useHomepageContentApi();
    let { aboutus, cases, reviews, articles } = content[0] || {};
    const fetchState = {isLoading, isError};
    useMetaTags();
    return (
        <div id={'homepage'} className={styles.home}>
            <Main/>
            <AboutUs {...fetchState} aboutus={aboutus}/>
            <Cases {...fetchState} cases={cases}/>
            <Reviews {...fetchState} reviews={reviews}/>
            <Blog {...fetchState} articles={articles}/>
        </div>
    );
}

export default Home;
