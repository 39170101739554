import {useEffect} from 'react';
import {Article} from '../../api/models/Article';
import {Case} from '../../api/models/Case';

const defaultMeta = {
    title: 'LTD FIVEWALLS | Web & Mobile Development Company',
    description: 'FiveWalls is top-notch on the IT market. We base our work on the principles of openness, predictability and responsibility. We are very interested in long term relationships with our clients, they are not just clients, they belong to our large family of business.',
    image: '',
    type: 'website'
};

function setContent(tag: HTMLMetaElement, value: string): void {
    tag.setAttribute('content', value);
}

export default function (article?: (Article|Case)) {
    useEffect(() => {

        const head = document.getElementsByTagName('head')[0];
        const metaTags = head.getElementsByTagName('meta');
        const description = metaTags.namedItem('description');
        const title = head.getElementsByTagName('title')[0];

        let og_title: HTMLMetaElement | undefined,
            og_image: HTMLMetaElement | undefined,
            og_description: HTMLMetaElement | undefined,
            og_type: HTMLMetaElement | undefined,
            og_url: HTMLMetaElement | undefined;

        Array.from(metaTags).forEach((tag) => {

            if (tag.getAttribute('property') === 'og:title') {
                og_title = tag;
            }
            if (tag.getAttribute('property') === 'og:image') {
                og_image = tag;
            }
            if (tag.getAttribute('property') === 'og:description') {
                og_description = tag;
            }
            if (tag.getAttribute('property') === 'og:type') {
                og_type = tag;
            }
            if (tag.getAttribute('property') === 'og:url') {
                og_url = tag;
            }
        });

        if (og_url) {
            setContent(og_url, window.location.href);
        }

        if (article) {

            if (article.meta_title && article.meta_description) {
                title.innerText = article.meta_title;
                if (description) {
                    setContent(description, article.meta_description);
                }
            }

            if (article.meta_og_title && article.meta_og_image && article.meta_og_description) {

                if (og_title) {
                    setContent(og_title, article.meta_og_title);
                }
                if (og_description) {
                    setContent(og_description, article.meta_og_description);
                }
                if (og_image) {
                    setContent(og_image, article.meta_og_image);
                }
                if (og_type) {
                    setContent(og_type, "article");
                }
            }
        }


        if (!article) {

            title.innerText = defaultMeta.title;
            if (description) {
                setContent(description, defaultMeta.description);
            }

            if (og_title) {
                setContent(og_title, defaultMeta.title);
            }
            if (og_description) {
                setContent(og_description, defaultMeta.description);
            }
            if (og_image) {
                setContent(og_image, defaultMeta.image);
            }
            if (og_type) {
                setContent(og_type, defaultMeta.type);
            }
        }

    }, [article]);
}
