import React from 'react';
//* styled with parent`s scss
import styles from '../case-page.module.scss';
import {RichText} from "prismic-reactjs";
import TagsBlock from "../../elements/tags-block/tagsBlock";
import InViewAnimation from "../../elements/intersection-animation/intersection-animation";


interface Props {
    isLoading: boolean;
    isError: boolean;
    content: any;
    tags?: string[] | false;
}

function SolutionSection(props: Props) {
    return (
        <section className={'white-background'}>
            <div className={styles.container}>
                <div className={`${styles.contentWrapper} ${styles.topLine}`}>
                    <div className={styles.title}>
                        <h4 className={styles.heading}>The solution</h4>
                    </div>
                    <div className={styles.content}>
                        {!props.isLoading && props.content && <>
                            {props.content.map((item: any, index: number) =>
                                <InViewAnimation threshold={0.4} key={index}>
                                    <div className={styles.titleItem}>
                                        <b>{item.subtitle}</b>
                                        <RichText render={item.text}/>
                                    </div>
                                </InViewAnimation>)}
                            {props.tags && <InViewAnimation threshold={0.4}>
                                <div className={styles.titleItem}>
                                    <b>Technologies used</b>
                                    <TagsBlock tags={props.tags}/>
                                </div>
                            </InViewAnimation>
                            }
                        </>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SolutionSection;