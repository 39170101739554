import {useEffect, useState} from "react";

export default function useReCAPTCHA() {

    const [siteKey, setSiteKey] = useState(process.env.REACT_APP_RECAPTCHA_KEY);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        document.body.appendChild(script);
    }, []);

    return siteKey;
}