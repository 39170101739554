//@ts-ignore
import {createPrismicLink} from "apollo-link-prismic";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import gql from "graphql-tag";
import IPrismicApiService from "./IPrismicApiService";
import {Basic} from "../models/Basic";

const gqlEndpoint: any = process.env.REACT_APP_GQL_PRISMIC_API_URL;


export default class GqlService<Model> implements IPrismicApiService<Model> {

    protected client: ApolloClient<any>;

    constructor() {

        this.client = new ApolloClient({
            link: createPrismicLink({
                uri: gqlEndpoint,
            }),
            cache: new InMemoryCache()
        });

    }

    public async index({query, ref}:{query: string, ref?:string}): Promise<Model[]> {
        let options: any = {
            query: gql`${query}`,
            fetchPolicy: 'no-cache'
        };
        if (ref) {
            options.context = {
                headers: {
                    'Prismic-ref': ref,
                }
            };
        }
        const doc = await this.client.query(options);
        
        return [this.makeModel(doc.data)];
    }

    protected makeModel(data: any) {
        return new Basic(data) as Model;
    }
}
