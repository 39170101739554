import React, {useEffect, useState} from 'react';
import {useCasesApi} from "../../hooks/useCasesApi";
import {Case} from "../../../api/models/Case";
import BoardBlock from "../elements/board-block/boardBlock";
import useMetaTags from "../../hooks/useMetaTags";


function CasesBoard() {

    const [content, setContent] = useState<Case[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const [{data: cases, isLoading, isError}] = useCasesApi({page});

    useEffect(() => {

        cases && setContent([...content, ...cases]);
        return () => {
            setContent([]);
        };
    }, [cases]);

    useEffect(() => {
        if (cases && content.length % 10 !== 0) {
            setHasMore(false);
        }
    });

    useMetaTags();

    return (
        <BoardBlock
            content={content}
            hasMore={hasMore}
            currentPage={page}
            setPage={() => setPage(page + 1)}
            isLoading={isLoading}
            blockTitle={'Cases'}
        />
    );
}

export default CasesBoard;
