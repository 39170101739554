import GqlService from './GqlService';
import {HomepageContent} from '../models/HomepageContent';

const query:string = `query{
  allHomepages(lang: "en-us") {
    edges {
      node {
        aboutus_image
        aboutus_text_large
        aboutus_text_medium
        aboutus_text_small
        articles {
          ... on HomepageArticles {
            article {
              ... on Blog_article {
                title
                description
                preview_image
                _meta{
                  id
                  type
                  uid
                }
              }
            }
          }
        }
        reviews {
          ...on HomepageReviews{
            review {
              ...on Review{
                review_text
                summary_text
                customer_name
                customer_photo
                company_name
                customer_position
                link_to_origin_review{
                  ... on _Linkable{
                    ... on _ExternalLink{
                      url
                    }
                  }
                }
              }
            }
          }
        }
        cases {
          ... on HomepageCases {
            case {
              ... on Case_study {
                project_name
                description
                preview_image
                _meta{
                  id
                  type
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export default class HomepageContentService extends GqlService<HomepageContent> {

    public async index(): Promise<HomepageContent[]> {
        return super.index({query});
    }

    protected makeModel(data: any) {
        return new HomepageContent(data);
    }
}