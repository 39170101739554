import {usePrismicApi} from './usePrismicApi';
import CaseService from '../../api/services/CaseService';
import {Case} from '../../api/models/Case';

export const useCasesApi = ({queryBy = 'document.type', value = 'case_study', pageSize = 10, page = 1} = {}) => {
    const caseService = new CaseService();

    return usePrismicApi<Case>({
        apiService: caseService,
        params: {queryBy, value, pageSize, page}
    });
};
