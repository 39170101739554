import React, {useRef, useState} from 'react';
import styles from './form-body.module.scss';
import useReCAPTCHA from "../../../../hooks/useReCAPTCHA";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

interface Props {
    onResponse: (isSuccessResponse: boolean) => void,
    onClose: () => void
}

const URL: any = process.env.REACT_APP_FORM_API_URL;

function FormBody(props: Props) {

    const inputRef = useRef<HTMLDivElement>(null);

    const siteKey = useReCAPTCHA();

    const toggleRequiredClassname = (ref: any, option: string) => {
        if (null !== ref.current) {
            if (option === 'add') {
                ref.current.classList.add(`${styles.required}`);
            }
            if (option === 'remove') {
                ref.current.classList.remove(`${styles.required}`);
            }
        }
    };

    const [form, setForm] = useState({
        email: '',
        firstName: '',
        lastName: '',
        message: '',
        allowCommunication: false,
        allowPersonalData: false,
        recaptchaToken: ''
    });

    const [loading, setLoading] = useState(false);
    const [isValid, setValid] = useState({
        email: false,
        allowPersonalData: false
    });

    const [emailAlert, setEmailAlert] = useState(false);
    const [personalDataAlert, setPersonalDataAlert] = useState(false);

    const changeInputHandler = (event: { target: { name: string; value: string | boolean } }) => {
        setForm({...form, [event.target.name]: event.target.value});
        if (event.target.name === 'email') {
            setEmailAlert(false);
            toggleRequiredClassname(inputRef, 'remove');
            if (event.target.value === '') {
                setEmailAlert(true);
                toggleRequiredClassname(inputRef, 'add');
                setValid({...isValid, [event.target.name]: false});
            } else {
                setValid({...isValid, [event.target.name]: true});
            }
        }
    };

    const changeCheckboxHandler = (event: { target: { name: string; checked: boolean } }) => {
        setForm({...form, [event.target.name]: event.target.checked});

        if (event.target.name === 'allowPersonalData') {
            setPersonalDataAlert(false);
            if (!event.target.checked) {
                setPersonalDataAlert(true);
                setValid({...isValid, [event.target.name]: false});
            } else {
                setValid({...isValid, [event.target.name]: true});
            }
        }
    };

    const submitHandler = (event: any) => {
        event.preventDefault();

        if (isValid.email && isValid.allowPersonalData) {
            //@ts-ignore
            grecaptcha.ready(function () {
                //@ts-ignore
                grecaptcha.execute(siteKey, {action: 'submit'}).then(function (token) {
                    setLoading(true);
                    form.recaptchaToken = token;
                    fetch(URL, {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(form)
                    }).then(res => {

                        (res.status === 200 || res.status === 201)
                            ? props.onResponse(true)
                            : props.onResponse(false);

                        setLoading(false);
                    });
                });
            });

        } else {
            if (!isValid.email) {
                setEmailAlert(true);
                toggleRequiredClassname(inputRef, 'add');
            }
            if (!isValid.allowPersonalData) {
                setPersonalDataAlert(true);
            }
        }

    };

    return (
        <form className={styles.contactForm} id={'contact-form'} onSubmit={submitHandler}>

            <button
                onClick={props.onClose}
                className={styles.closeBtn}
            >
                <FontAwesomeIcon className={styles.closeIcon} icon={faTimes}/>
            </button>

            <h3 className={styles.formHeader}>Contact Us</h3>

            <div className={styles.inputBlock}>
                <label htmlFor={'email'}>
                    <span>Email</span>
                    <span className={styles.mandatory}>*</span>
                    <div className={styles.requiredWrapper} ref={inputRef}>
                        <input
                            className={styles.formInput}
                            type='email'
                            name={'email'}
                            value={form.email}
                            onChange={changeInputHandler}
                        />
                        {emailAlert && <div>Please complete this required field.</div>}
                    </div>
                </label>
            </div>

            <div className={styles.inputBlock}>
                <label htmlFor={'firstName'}>
                    <span>First name</span>
                    <div>
                        <input
                            className={styles.formInput}
                            type='text'
                            name={'firstName'}
                            value={form.firstName}
                            onChange={changeInputHandler}
                        />
                    </div>
                </label>
            </div>

            <div className={styles.inputBlock}>
                <label htmlFor={'lastName'}>
                    <span>Last name</span>
                    <div>
                        <input
                            className={styles.formInput}
                            type='text'
                            name={'lastName'}
                            value={form.lastName}
                            onChange={changeInputHandler}
                        />
                    </div>
                </label>
            </div>

            <div className={styles.inputBlock}>
                <label htmlFor={'message'}>
                    <span>Message</span>
                    <div>
                    <textarea
                        className={styles.formInput}
                        name={'message'}
                        value={form.message}
                        onChange={changeInputHandler}
                    />
                    </div>
                </label>
            </div>

            <p className={`${styles.formText} small`}>
                LTD FIVEWALLS is committed to protecting and respecting your privacy, and we’ll only use your personal
                information to administer your account and to provide the products and services you requested from us.
            </p>

            <div className={`${styles.checkboxBlock} ${styles.requiredWrapper}`}>
                <label htmlFor={'allowPersonalData'} className={styles.checkboxLabel}>
                    <input
                        className={styles.formCheckbox}
                        id={'allowPersonalData'}
                        name={'allowPersonalData'}
                        type='checkbox'
                        checked={form.allowPersonalData}
                        onChange={changeCheckboxHandler}
                    />
                    &nbsp;I agree to allow LTD FIVEWALLS to store and process my personal data.
                    <span className={styles.mandatory}>*</span>
                </label>
                {personalDataAlert && <div>Please complete this required field.</div>}
            </div>

            <p className={`${styles.formText} small`}>
                We also would like have an opportunity to contact you about our products and services, as well as other
                content that may be of interest to you.
            </p>

            <div className={styles.checkboxBlock}>
                <label htmlFor={'allowCommunication'} className={styles.checkboxLabel}>
                    <input
                        className={styles.formCheckbox}
                        id={'allowCommunication'}
                        name={'allowCommunication'}
                        type='checkbox'
                        checked={form.allowCommunication}
                        onChange={changeCheckboxHandler}
                    />
                    &nbsp;I agree to receive other communications from LTD FIVEWALLS.
                </label>
            </div>

            <p className={`${styles.formText} small`}>
                You can unsubscribe from these communications at any time.
            </p>

            <div className={styles.actions}>
                <button
                    onClick={submitHandler}
                    className={styles.submit}
                    form='contact-form'
                    value="Submit"
                    type='submit'
                >
                    {!loading && 'Submit'}
                    {loading &&
                    <div className={`${styles.spinner}`}>
                        <div className={styles.bounce1}/>
                        <div className={styles.bounce2}/>
                        <div className={styles.bounce3}/>
                    </div>
                    }
                </button>
            </div>


        </form>
    );
}

export default FormBody;