import {Document} from './Document';

export class Legal extends Document {
    public uid: string;
    public title: string;
    public section_title: [];
    public section_text: [];

    constructor(doc: any) {
        super(doc);
        this.uid = doc.uid;
        this.title = doc.data.section;
        this.section_title = doc.data.title;
        this.section_text = doc.data.text;
    }
}
