import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useArticleByUidApi} from "../../hooks/useArticleByUidApi";
import useMetaTags from "../../hooks/useMetaTags";
import ArticleBody from "./article-body/articleBody";

function ArticlePage({history}:any) {
    const {uid: value} = useParams() as any;
    const {data: article, isLoading, isError} = useArticleByUidApi({section:'blog_article', value});
    const fetchState = {isLoading, isError};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [value]);

   useMetaTags(article);

    if (!isLoading && !article) {
        history.push('/404-page');
        return (<></>);
    }


    return (
        <ArticleBody {...fetchState} data={article}/>
    );

}

export default ArticlePage;
