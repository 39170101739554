import {Basic} from "./Basic";
import {Article} from "./Article";
import {Case} from "./Case";
import {Review} from "./Review";
import {AboutUs} from "./AboutUs";

export class HomepageContent extends Basic {
    public aboutus: AboutUs;
    public articles: Article[];
    public cases: Case[];
    public reviews: Review[];


    constructor(doc: any) {
        super(doc);

        this.aboutus = new AboutUs(doc.allHomepages.edges[0].node);

        this.articles = doc.allHomepages.edges[0].node.articles.map((item: any) => {
            return new Article({
                uid: item.article._meta.uid,
                type: item.article._meta.type,
                data: {
                    ...item.article,
                }
            });
        });

        this.cases = doc.allHomepages.edges[0].node.cases.map((item: any) => {
            return new Case({...item.case});
        });

        this.reviews = doc.allHomepages.edges[0].node.reviews.map((item: any) => {
            item.data = {...item.review};
            return new Review(item);
        });
    }
}
