import React from 'react';
import {useArticlesApi} from "../../hooks/useArticlesApi";
import {useCasesApi} from "../../hooks/useCasesApi";
import {Link, useParams} from "react-router-dom";

function SnapCrawlerHelper() {

    const {token} = useParams() as any;
    const tokenMatch = token === 'pKPQRr7Sdy';

    const [{data: articles}] = useArticlesApi({pageSize: 100});
    const [{data: cases}] = useCasesApi({pageSize: 100});

    return (
        <>
            {tokenMatch && articles.map((item, index) =>
                <Link to={`/blog/${item.uid}`} key={index}>{item.title}</Link>
            )}
            {tokenMatch && cases.map((item, index) =>
                <Link to={`/cases/${item.uid}`} key={index}>{item.title}</Link>
            )}
        </>
    );
}

export default SnapCrawlerHelper;