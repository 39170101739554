import styles from './look-for-us.module.scss';
import React from 'react';
import {useRepositoriesApi} from "../../../../hooks/useRepositoriesApi";
import {Repository} from "../../../../../api/models/Repository";

function LookForUs() {
    let [{data: repositories, isLoading, isError}] = useRepositoriesApi();
    return (
        <div className={styles.wrapper}>
            <span className={styles.lookForUsWording}>Look for us</span>
            {repositories.map((repo: Repository) =>
                <a href={repo.link}
                   className={styles.link}
                   target="_blank"
                   rel="noopener noreferrer"
                   key={repo.id}>
                    <img className={styles.image} src={repo.image} alt={repo.title + " logo"}/>
                </a>
            )}
        </div>
    );
}

export default LookForUs;
