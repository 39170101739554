import React, {useEffect, useState} from "react";
import styles from './circle-underlay.module.scss';
import {ApplicationState} from "../../../redux/reducers";
import {actionCloseMenu} from "../../../redux/actions/actionsNavmenu";
import {connect} from "react-redux";
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";

interface Props {
    isOpen: boolean;
    closeMenu: () => void;
}

function CircleUnderlay({isOpen, closeMenu}: Props) {
    const [wasOpened, setOpened] = useState('');

    useEffect(() => {

        if (isOpen) {
            setOpened(`${styles.isActive}`);
        }
        if (wasOpened !== '' && !isOpen) {
            setOpened(`${styles.notActive}`);
        }
    }, [isOpen]);

    useLockBodyScroll();

    return (
        <div
            id={'spreadCircle'}
            className={`${styles.spreadCircle} ` + wasOpened}
            onClick={closeMenu}
        />
    );
}

function DelayedUnmount({isOpen, closeMenu}: Props) {
    const [mounted, setMounted] = useState(false);
    const DELAY = 750;

    useEffect(() => {
        if (isOpen) {
            setMounted(true);
        } else if (!isOpen) {
            setTimeout(() => {
                setMounted(false);
            }, DELAY);
        }
    }, [isOpen]);

    return (
        <>
            {mounted && <CircleUnderlay isOpen={isOpen} closeMenu={closeMenu}/>}
        </>
    );
}

export default connect(
    (state: ApplicationState) => ({
        isOpen: state.navmenu.isOpen
    }),
    {
        closeMenu: actionCloseMenu
    }
)(DelayedUnmount);
