import React, {useEffect} from 'react';
import styles from './not-found-page.module.scss';

function NotFoundPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={styles.notFoundPage}>
            <div className={styles.messageWrapper}>
                <h1 className={styles.notFoundMessage}>404 - page not found</h1>
                <p>The page you are looking for was moved, removed, renamed or might never existed.</p>
            </div>
        </section>
    );
}

export default NotFoundPage;

//6,7,10 lines to test