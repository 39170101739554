import {Document} from './Document';

export class Repository extends Document {
    public link: string;
    public image: string;
    public title: string;

    constructor(doc: any) {
        super(doc);

        this.link = doc.data.link.url;
        this.image = doc.data.image.url;
        this.title = doc.data.title;
    }
}
