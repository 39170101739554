import React from 'react';
import styles from '../annotationBlock.module.scss';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

interface Props {
    type: string,
    haveSubtitle?: boolean
}

function SkeletonAnnotation({haveSubtitle, type}: Props) {
    return (
        <div className={styles.container}>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton className={
                    type === 'article'
                        ? `${styles.image} ${styles.small}`
                        : `${styles.image}`
                }
                          width={'100%'}
                />
                {haveSubtitle && <Skeleton width={'45%'}/>}
                <p>
                    <Skeleton count={2} />
                </p>
                <Skeleton width={'50%'}/>
            </SkeletonTheme>
        </div>
    );
}

export default SkeletonAnnotation;