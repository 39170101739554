import React from 'react';
//* styled with parent`s scss
import styles from '../case-page.module.scss';
import InViewAnimation from '../../elements/intersection-animation/intersection-animation';
import {ClientInfo} from "../../../../api/models/CaseStudy";
import {RichText} from "prismic-reactjs";
import {imageUrlParamsHandler} from "../../../helpers/imageUrlParamsHandler";


interface Props {
    isLoading: boolean;
    isError: boolean;
    content: ClientInfo;
}


function InfoSection(props: Props) {
    return (
        <section id={'about-client'} className={'white-background'}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <div className={styles.title}>
                        <InViewAnimation right threshold={0.4}>
                            <div className={styles.titleContainer}>
                                <div className={styles.titleItem}>
                                    <b>Client</b>
                                    {!props.isLoading && props.content && <img
                                        src={imageUrlParamsHandler(props.content.logo, {width: 100})}
                                        width={'100px'}
                                        alt={`client logo`}
                                    />}
                                </div>
                                <div className={styles.titleItem}>
                                    <b>Industries</b>
                                    {!props.isLoading && props.content && <span>{props.content.industries}</span>}
                                </div>
                                <div className={styles.titleItem}>
                                    <b>Date</b>
                                    {!props.isLoading && props.content && <span>{props.content.date}</span>}
                                </div>
                            </div>
                        </InViewAnimation>
                    </div>
                    <div className={styles.content}>
                        <InViewAnimation threshold={0.4}>
                            {!props.isLoading && props.content && <RichText render={props.content.text as any}/>}
                        </InViewAnimation>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InfoSection;