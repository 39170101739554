import {Document} from "./Document";

export class Case extends Document {

    public id: number;
    public type: string;
    public uid: string;
    public title: string;
    public preview_text: string;
    public preview_image: string;
    public meta_title?: string;
    public meta_description?: string;
    public meta_og_title?: string;
    public meta_og_description?: string;
    public meta_og_image?: string;

    constructor(doc: any) {
        super(doc);

        doc = {...doc, ...doc.data};

        this.id = doc.id || doc._meta.id;
        this.type = doc.type || doc._meta.type;
        this.uid = doc.uid || doc._meta.uid;
        this.title = doc.project_name;
        this.preview_text = doc.description;
        this.preview_image = doc.preview_image.url;
        this.meta_title = doc?.meta_title;
        this.meta_description = doc?.meta_description;
        this.meta_og_title = doc.body1?.[0]?.primary?.card_title?.[0]?.text;
        this.meta_og_description = doc.body1?.[0]?.primary?.card_description?.[0]?.text;
        this.meta_og_image = doc.body1?.[0]?.primary?.image?.url;
    }
}