import styles from "../footer.module.scss";
import React from "react";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

function ContactsSkeleton() {
    return (
        <div className={styles.container}>
            <div className={styles.contacts}>
                <SkeletonTheme baseColor="#D4D4D4" highlightColor="#B8B8B8">
                    <p>
                        <Skeleton width={'90%'}/>
                    </p>
                    <p>
                        <Skeleton width={'90%'}/>
                    </p>
                </SkeletonTheme>
            </div>
            <div className={styles.address}>
                <SkeletonTheme baseColor="#D4D4D4" highlightColor="#B8B8B8">
                    <p className={styles.addressText}>
                        <Skeleton count={2} width={'90%'}/>
                    </p>
                </SkeletonTheme>
            </div>
        </div>
    );
}

export default ContactsSkeleton;