import React, {useEffect} from 'react';
import {useLegalApi} from '../../hooks/useLegalApi';
import {Legal} from '../../../api/models/Legal';
import styles from './legal.module.scss';
import {NavLink, useLocation} from "react-router-dom";
import {useParams} from 'react-router-dom';
import {RichText} from 'prismic-reactjs';
import useMetaTags from "../../hooks/useMetaTags";

function LegalPage() {
    let [{data: legalDocs, isLoading, isError}] = useLegalApi();
    let {id} = useParams() as any;
    const currentSection: any = legalDocs.find(legal => legal.uid === id) || {};
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useMetaTags();

    return (
        <section className={`white-background ${styles.legalSection}`}>
            <div className={styles.container}>
                <nav className={styles.navContainer}>
                    {legalDocs.map((legal: Legal) =>
                        <NavLink to={`/legal/${legal.uid}`}
                                 style={({ isActive }) => ({
                                     color: isActive ? '#F1B535' : '',
                                     borderBottom:  isActive ? '1px solid #F1B535' : ''
                                 })}
                                 key={legal.id}
                        >
                            {legal.title}
                        </NavLink>
                    )}
                </nav>
                {!isLoading && <article className={styles.articleContainer}>
                    <RichText render={currentSection.section_title}/>
                    <RichText render={currentSection.section_text}/>
                </article>}
            </div>
        </section>
    );
}

export default LegalPage;
