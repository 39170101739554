import DocumentService from './DocumentService';
import {Repository} from '../models/Repository';

export default class RepositoryService extends DocumentService<Repository> {

    public async index(): Promise<Repository[]> {
        return super.index({queryBy: 'document.type', value: 'repository'});
    }

    protected makeModel(doc: any) {
        return new Repository(doc);
    }
}