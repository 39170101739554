import React, {useEffect} from 'react';
import styles from './board-block.module.scss';
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonAnnotation from "../../elements/annotation-block/skeleton-annotation/skeletonAnnotation";
import AnnotationBlock from "../../elements/annotation-block/annotationBlock";
import {Article} from "../../../../api/models/Article";
import {Case} from "../../../../api/models/Case";

interface Props {
    content: any,
    hasMore: boolean,
    currentPage: number,
    setPage: () => void,
    isLoading: boolean,
    blockTitle: string
}

function PageLoader() {
    return (
        <>
            {Array(2)
                .fill('', 0)
                .map((item, index) =>
                    <div className={styles.annotation} key={index}>
                        <SkeletonAnnotation type={'case'}/>
                    </div>
                )
            }
        </>
    );
}

function BoardBlock(props: Props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <section className={styles.section}>

            <h1 className={styles.title}>{props.blockTitle}</h1>
            <div className={styles.container}>

                {props.isLoading && Array(10)
                    .fill('', 0)
                    .map((item, index) =>
                        <div className={styles.annotation} key={index}>
                            <SkeletonAnnotation type={'case'}/>
                        </div>
                    )}

            </div>

            {!props.isLoading &&

            <InfiniteScroll
                className={styles.container}
                style={{overflow: 'initial'}}
                dataLength={props.content.length}
                next={props.setPage}
                hasMore={props.hasMore}
                loader={<PageLoader/>}
                endMessage={<span className={styles.endMessage}>Yay! You have seen it all</span>}
            >

                {props.content.map((item:(Article|Case)) =>
                    <div className={styles.annotation} key={item.id}>
                        <AnnotationBlock
                            img={item.preview_image}
                            title={item.title}
                            uid={item.uid}
                            subtitle={item.preview_text}
                            type={item.type}
                        />
                    </div>
                )}

            </InfiniteScroll>

            }

        </section>

    );
}

export default BoardBlock;