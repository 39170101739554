import React from 'react';
import styles from '../userReview.module.scss';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";


function SkeletonReview() {
    return (

        <div className={`${styles.container} ${styles.card}`} style={{minWidth: '275px', width: '100%'}}>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <p style={{marginTop: '0'}}>
                    <Skeleton count={4}/>
                </p>
            </SkeletonTheme>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton circle={true} width={48} height={48} style={{marginRight: '12px'}}/>
                </SkeletonTheme>
                <div style={{width: '50%'}}>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton/>
                        <Skeleton/>
                    </SkeletonTheme>
                </div>
            </div>
        </div>
    );
}

export default SkeletonReview;