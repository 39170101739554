import React from 'react';
import styles from './form-message.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

interface Props {
    onBtnClick: ()=>void,
    isSuccess: boolean
}

function FormMessage(props:Props) {

    const SuccessMessage = () =>
        <>
            <h4>Got it!</h4>
            <p>Thank you for your interest.
                <br/>
                Our Project Manager will contact you soon!
            </p>
        </>;

    const FailureMessage = () =>
        <>
            <h4>Oh, Snap!..</h4>
            <p>Something went wrong and your request was not delivered.
                <br/>
                Please try to send one more request a bit later.
            </p>
        </>;

    return (
        <div className={styles.messageContainer}>
                <div className={styles.icon}>
                    {props.isSuccess && <FontAwesomeIcon icon={faCheckCircle} />}
                    {!props.isSuccess && <FontAwesomeIcon className={styles.failureIcon} icon={faTimesCircle} />}
                </div>
                <div className={styles.message}>
                    {props.isSuccess && <SuccessMessage/>}
                    {!props.isSuccess && <FailureMessage/>}
                </div>
                <button onClick={props.onBtnClick} className={styles.btn}>
                    Close
                </button>
        </div>
    );
}

export default FormMessage;