import {Basic} from "./Basic";

export class AboutUs extends Basic {
    public image: string;
    public text_large: object;
    public text_medium: object;
    public text_small: object;

    constructor(doc: any) {
        super(doc);
        this.image = doc.aboutus_image.url;
        this.text_large = doc.aboutus_text_large;
        this.text_medium = doc.aboutus_text_medium;
        this.text_small = doc.aboutus_text_small;
    }
}