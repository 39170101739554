import React from 'react';
import styles from './about-us.module.scss';
import {RichText} from 'prismic-reactjs';
import {AboutUs} from '../../../../api/models/AboutUs';
import {AboutUsSkeletonImage, AboutUsSkeletonText} from './about-us-skeleton/about-us-skeleton';
import {imageUrlParamsHandler} from '../../../helpers/imageUrlParamsHandler';
import InViewAnimation from "../../elements/intersection-animation/intersection-animation";

interface Props {
    isLoading: boolean;
    isError: boolean;
    aboutus: AboutUs | null
}

function AboutUsSection(props: Props) {
    return (
        <section id={'aboutUs'} className={styles.aboutUsSection}>
            <div className={styles.contentWrapper}>
                <span>Who We Are</span>
                <InViewAnimation threshold={0.4} right>
                    <h2 className={styles.mt1}>We Are Fivewalls<span className={styles.wordWrapper}>.</span></h2>
                </InViewAnimation>
                <div className={styles.catchPhrase}>

                    {(props.isLoading || !props.aboutus) && <AboutUsSkeletonText/>}

                    {!props.isLoading && props.aboutus &&
                    <InViewAnimation threshold={0.4}>
                        <div className={styles.textLarge}>
                            <RichText render={props.aboutus.text_large as any}/>
                        </div>
                        <div className={styles.textMedium}>
                            <RichText render={props.aboutus.text_medium as any}/>
                        </div>
                        <div className={styles.textSmall}>
                            <RichText render={props.aboutus.text_small as any}/>
                        </div>
                    </InViewAnimation>
                    }

                </div>
                <ul className={styles.featuresList}>
                    <li><span className={styles.small}>Website & Web App Development</span></li>
                    <li><span className={styles.small}>Mobile Apps Development</span></li>
                </ul>
            </div>
            <div className={`${styles.fullPageImageContainer} ${styles.mt4}`}>
                {(props.isLoading || !props.aboutus) && <AboutUsSkeletonImage/>}
                {!props.isLoading && props.aboutus &&
                <>
                    <div className={styles.animated}>
                        <InViewAnimation threshold={0.4} left>
                            <picture>
                                <source
                                    srcSet={imageUrlParamsHandler(props.aboutus.image, {width: 800})}
                                    media="(min-width: 1440px)"
                                />
                                <img
                                    className={styles.fullPageImage}
                                    src={imageUrlParamsHandler(props.aboutus.image, {width: 600})}
                                    alt="developers at work"
                                />
                            </picture>
                        </InViewAnimation>
                    </div>
                    <div className={styles.notAnimated}>
                        <picture>
                            <source
                                srcSet={imageUrlParamsHandler(props.aboutus.image, {width: 1024})}
                                media="(min-width: 768px)"
                            />
                            <source
                                srcSet={imageUrlParamsHandler(props.aboutus.image, {width: 768})}
                                media="(min-width: 480px)"
                            />
                            <img
                                className={styles.fullPageImage}
                                src={imageUrlParamsHandler(props.aboutus.image, {width: 480})}
                                alt="developers at work"
                            />
                        </picture>
                    </div>
                </>}
            </div>
        </section>
    );
}

export default AboutUsSection;

