import {Basic} from "./Basic";

export class Document extends Basic {
    public id: number;
    public type: string;

    constructor(doc: any) {
        super(doc);
        this.id = doc.id;
        this.type = doc.type;
    }
}
