import React, {useEffect, useState} from 'react';
import styles from './links-container.module.scss';
import {NavLink} from "react-router-dom";
import {ApplicationState} from '../../../redux/reducers';
import {connect} from "react-redux";
import {actionCloseMenu} from "../../../redux/actions/actionsNavmenu";
// @ts-ignore
import {NavHashLink as Link} from 'react-router-hash-link';

interface Props {
    isOpen: boolean;
    closeMenu: () => void;
}

function LinksContainer({isOpen, closeMenu}: Props) {
    const [wasOpened, setOpened] = useState('');

    useEffect(() => {

        if (isOpen) {
            setOpened(`${styles.isActive}`);
        }
        if (wasOpened !== '' && !isOpen) {
            setOpened(`${styles.notActive}`);
        }
    }, [isOpen]);

    return (
        <nav
            className={`linkLayer ` + wasOpened}
            onClick={closeMenu}
        >
            <ul className={styles.linkContainer}>
                <li className={styles.navLink}>
                    <Link smooth to="/#aboutUs">Us</Link>
                </li>
                <li className={styles.navLink}>
                    <NavLink
                        end
                        to="/cases"
                        style={({ isActive }) => ({ color: isActive ? '#F1B535' : '' })}
                        className={({ isActive }) => (isActive ? styles.active : "")}
                    >Cases</NavLink>
                </li>
                <li className={styles.navLink}>
                    <NavLink
                        end
                        to="/blog"
                        style={({ isActive }) => ({ color: isActive ? '#F1B535' : '' })}
                        className={({ isActive }) => (isActive ? styles.active : "")}
                    >Articles</NavLink>
                </li>
                <li className={styles.navLink}>
                    <Link smooth to="/#contactsSection">Contacts</Link>
                </li>
            </ul>
        </nav>
    );
}


export default connect(
    (state: ApplicationState) => ({
        isOpen: state.navmenu.isOpen
    }),
    {closeMenu: actionCloseMenu}
)(LinksContainer);
