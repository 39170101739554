import React from 'react';

import styles from './user.module.scss';
import {imageUrlParamsHandler} from '../../../../helpers/imageUrlParamsHandler';

interface UserProps {
    avatar: string,
    fullName: string,
    position: string,
    card: boolean;
}

function User (props: UserProps) {
    const picSize = props.card ? 48 : 64;
    return (
        <div className={styles.container}>
            <img
                src={imageUrlParamsHandler(props.avatar, {width:picSize, height:picSize})}
                alt={`avatar ${props.fullName}`}
                className={styles.image}
                width={picSize}
                height={picSize}
            />
            <div className={styles.userInfo}>
                <span className={`${styles.name} ${styles.small}`}>{props.fullName}</span>
                <span className={styles.position}>{props.position}</span>
            </div>
        </div>
    );
}

export default User;
