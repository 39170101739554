import {Document} from './Document';

export class Review extends Document {
    public customer_name: string;
    public customer_photo: string;
    public customer_photo_alt: string;
    public customer_company: string;
    public customer_position: string;
    public link_on_origin_review: string;
    public text: string;
    public summary: string;

    constructor(doc: any) {
        super(doc);

        const {review_text, customer_name, customer_photo, company_name, customer_position, link_to_origin_review, summary_text} = doc.data;

        this.text                  = review_text;
        this.summary               = summary_text;
        this.customer_name         = customer_name;
        this.customer_photo        = customer_photo.url;
        this.customer_photo_alt    = customer_photo.alt;
        this.customer_company      = company_name;
        this.customer_position     = customer_position;
        this.link_on_origin_review = link_to_origin_review.url;
    }
}
