import React from 'react';
import User from '../user/user';
import styles from './userReview.module.scss';
import {Review} from '../../../../../api/models/Review';

interface Props extends Review {
    card: boolean;
}

function UserReview(props: Props) {
    const cardStyle = props.card ? `${styles.card}` : '';
    const content = props.card ? props.text : props.summary;
    return (
        <a className={`${styles.container} ` + cardStyle}
           href={props.link_on_origin_review}
           target="_blank"
           rel="noopener noreferrer"
        >
            <p className={`${styles.text} ${styles.small}`}><span>&ldquo;</span>{content}<span>&rdquo;</span></p>
            <User
                avatar={props.customer_photo}
                fullName={props.customer_name}
                position={props.customer_position}
                card={props.card}
            />
        </a>
    );
}

export default UserReview;
