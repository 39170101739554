import DocumentService from './DocumentService';
import {Article} from '../models/Article';
import {Case} from '../models/Case';

export default class ArticleCaseService extends DocumentService<Article | Case> {

    public async index(params: object): Promise<(Article|Case)[]> {
        return super.index({...params});
    }

    protected makeModel(data: any) {
        if (data.type === 'blog_article') {
            return new Article(data);
        } else {
            return new Case(data);
        }
    }
}




