import DocumentService from './DocumentService';
import {Case} from '../models/Case';

export default class CaseService extends DocumentService<Case> {

    public async index(params: object): Promise<Case[]> {
        return super.index({...params});
    }

    protected makeModel(doc: any) {
        return new Case(doc);
    }
}
