import React, {useEffect, useState} from 'react';
import styles from './modal.module.scss';
import Modal from 'react-modal';
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import ContactForm from "../contact-form/contactForm";

interface ModalFormProps {
    isOpen: boolean,
    onRequestClose: any,
    background: string
}

function ModalForm(props: ModalFormProps) {
    const [isActive, setActive] = useState('');

    useLockBodyScroll();

    useEffect(() => {
        if (props.isOpen) {
            setTimeout(() => {
                setActive(`${styles.isActive}`);
            }, 20);
        }
    });

    const closeHandler = () => {
        setActive('');
        props.onRequestClose();
    };

    useEffect(() => {
        window.history.pushState(null, 'modalOpened');
        window.onpopstate = (event: any) => {
            event.preventDefault();
            closeHandler();
        };
        return () => {
            window.onpopstate = (e: any) => true;
        };
    }, []);

    const overlayColor = props.background === 'white' ? 'rgba(87, 87, 87, 0.75)' : 'rgba(115, 115, 115, 0.75)';

    return (
        <Modal
            ariaHideApp={false}
            isOpen={props.isOpen}
            onRequestClose={closeHandler}
            className={`${styles.modal} ` + isActive}
            style={{
                overlay: {
                    backgroundColor: `${overlayColor}`,
                    zIndex: 2000
                }
            }}
        >
            <div className={styles.formContainer}>
                <ContactForm onCloseModal={closeHandler}/>
            </div>
            <span className={styles.topBorder}/>
            <span className={styles.bottomBorder}/>
            <span className={styles.rightBorder}/>
            <span className={styles.leftBorder}/>
        </Modal>
    );
}

export default ModalForm;