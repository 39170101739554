import React from 'react';
//* styled with parent`s scss
import styles from '../case-page.module.scss';
import {RichText} from "prismic-reactjs";
import InViewAnimation from "../../elements/intersection-animation/intersection-animation";

interface Props {
    isLoading: boolean;
    isError: boolean;
    content: object[];
    title: string;
}

function ChallengeDoneSection(props: Props) {
    return (
        <section>
            <div className={styles.container}>
                <div className={`${styles.contentWrapper} ${styles.topLine}`}>
                    <div className={styles.title}>
                        <h4 className={styles.heading}>{props.title}</h4>
                    </div>
                    <div className={styles.content}>
                        <InViewAnimation threshold={0.1}>
                            {!props.isLoading && props.content && <RichText render={props.content as any}/>}
                        </InViewAnimation>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ChallengeDoneSection;