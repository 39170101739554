import DocumentService from './DocumentService';
import {Contact} from '../models/Contact';

export default class ArticleService extends DocumentService<Contact> {

    public async index(): Promise<Contact[]> {
        return super.index({queryBy: 'document.type', value: 'contacts'});
    }

    protected makeModel(data: any) {
        return new Contact(data);
    }
}