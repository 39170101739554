import React, {useRef, useState} from 'react';
// @ts-ignore
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './images-gallery.module.scss';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {imageUrlParamsHandler} from "../../../helpers/imageUrlParamsHandler";
import Button from "../../elements/button/button";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExpand} from '@fortawesome/free-solid-svg-icons';
import InViewAnimation from "../../elements/intersection-animation/intersection-animation";


interface Props {
    isLoading: boolean;
    isError: boolean;
    content: any;
}

const sliderSettings = {
    speed: 300,
    arrows: false,
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    draggable: false,
    className: "gallery"
};

function ImagesGallery(props: Props) {

    const [lightbox, setLightbox] = useState({
        picIndex: 0,
        isOpen: false,
    });

    const [skip, setSkip] = useState(false);

    const images = props.content && props.content.map((image: { url: string; }) => `${imageUrlParamsHandler(image.url, {width: window.innerWidth})}`);

    const clickHandler = (index: number) => {
        setSkip(true);
        setLightbox({isOpen: true, picIndex: index});
    };

    const sliderRef: any = useRef(null);

    function handleNext(e: any) {
        e.stopPropagation();
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    }

    function handlePrevious(e: any) {
        e.stopPropagation();
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    }

    return (
        <section className={styles.gallerySection}>
            {!props.isLoading && props.content && <>

                <div className={styles.imageList}>

                    {props.content.map((image: any, index: number) =>
                        <div
                            className={styles.imageListItem}
                            key={index}
                            onClick={() => clickHandler(index)}
                        >
                            <InViewAnimation threshold={0.2} right skip={skip}>
                                <div className={styles.iconOverlay}/>
                                <FontAwesomeIcon className={styles.scopeIcon} icon={faExpand}/>
                                <picture>
                                    <source
                                        srcSet={imageUrlParamsHandler(image.url, {width: 600})}
                                        media="(min-width: 480px)"
                                    />
                                    <img
                                        className={styles.galleryImg}
                                        src={imageUrlParamsHandler(image.url, {width: 480})}
                                        alt={image.alt}
                                    />
                                </picture>
                            </InViewAnimation>
                        </div>
                    )
                    }
                </div>

                <div className={styles.sliderWrapper}>
                    <InViewAnimation threshold={0} right skip={skip}>
                        <Button className={`${styles.circleButton} ${styles.leftArrow}`}
                                onClick={handlePrevious}
                                circular={true}
                                aria-label={'previous image'}
                        />
                    </InViewAnimation>

                    <InViewAnimation threshold={0.2} skip={skip}>
                        {/*! slider class is called "gallery" and described in index.scss*/}
                        <Slider ref={sliderRef} {...sliderSettings}>

                            {props.content.map((image: any, index: number) =>
                                <div
                                    className={styles.imgWrapper}
                                    key={index}
                                    onClick={() => clickHandler(index)}>

                                    <picture>
                                        <source
                                            srcSet={imageUrlParamsHandler(image.url, {width: 1200})}
                                            media="(min-width: 1440px)"
                                        />
                                        <source
                                            srcSet={imageUrlParamsHandler(image.url, {width: 900})}
                                            media="(min-width: 1024px)"
                                        />
                                        <img
                                            className={styles.galleryImg}
                                            src={imageUrlParamsHandler(image.url, {width: 680})}
                                            alt={image.alt}
                                        />
                                    </picture>
                                </div>
                            )}
                        </Slider>
                    </InViewAnimation>

                    <InViewAnimation threshold={0} left skip={skip}>
                        <Button className={`${styles.circleButton} ${styles.rightArrow}`}
                                onClick={handleNext}
                                circular={true}
                                aria-label={'next image'}
                        />
                    </InViewAnimation>

                    {lightbox.isOpen && !props.isLoading && props.content && (
                        <Lightbox
                            mainSrc={images[lightbox.picIndex]}
                            nextSrc={images[(lightbox.picIndex + 1) % images.length]}
                            prevSrc={images[(lightbox.picIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => setLightbox({...lightbox, isOpen: false})}
                            onMovePrevRequest={() =>
                                setLightbox({
                                    ...lightbox,
                                    picIndex: (lightbox.picIndex + images.length - 1) % images.length
                                })
                            }
                            onMoveNextRequest={() =>
                                setLightbox({
                                    ...lightbox,
                                    picIndex: (lightbox.picIndex + 1) % images.length
                                })
                            }
                        />
                    )}

                </div>
            </>}
        </section>
    );
}

export default ImagesGallery;