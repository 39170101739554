import React from 'react';
import styles from './next-case.module.scss';
import {imageUrlParamsHandler} from "../../../helpers/imageUrlParamsHandler";
import {Link} from "react-router-dom";

interface Props {
    isLoading: boolean;
    isError: boolean;
    content: any;
}

function NextCase(props: Props) {

    let backgroundImage = props.content ? `url(${imageUrlParamsHandler(props.content.image, {width: window.innerWidth})})` : '';

    return (<>
            <section className={styles.nextCaseSection}>
                <div className={styles.overlay}/>
                {!props.isLoading && props.content &&
                <Link to={`/cases/${props.content.uid}`} className={styles.link}>
                    <div className={styles.contentWrapper}>
                        <span>Next Case</span>
                        <h3 className={`${styles.heading} ${styles.glowText}`}>{props.content.title}</h3>
                        <span className={styles.description}>{props.content.description}</span>
                    </div>
                </Link>
                }
                <div className={styles.sectionBackground} style={{backgroundImage: backgroundImage}}/>
            </section>
        </>
    );
}

export default NextCase;