import React from 'react';
import styles from '../about-us.module.scss';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

export function AboutUsSkeletonText() {
    return (<>
            <div className={styles.textLarge}>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <p>
                        <Skeleton count={1}/>
                    </p>
                    <p>
                        <Skeleton count={2}/>
                    </p>
                    <p>
                        <Skeleton count={2}/>
                    </p>
                    <p>
                        <Skeleton count={2}/>
                    </p>
                </SkeletonTheme>
            </div>
            <div className={styles.textMedium}>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <p>
                        <Skeleton count={6}/>
                    </p>
                </SkeletonTheme>
            </div>
            <div className={styles.textSmall}>
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <p>
                        <Skeleton count={7}/>
                    </p>
                </SkeletonTheme>
            </div>
        </>
    );
}

export function AboutUsSkeletonImage() {
    return (
        <div className={styles.fullPageImage}>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton className={styles.imageSkeleton}/>
            </SkeletonTheme>
        </div>
    );
}
