import {usePrismicApi} from './usePrismicApi';
import RepositoryService from '../../api/services/RepositoryService';
import {Repository} from '../../api/models/Repository';


export const useRepositoriesApi = () => {
    const repositoryService = new RepositoryService();

    return usePrismicApi<Repository>({
        apiService: repositoryService,
        params: { }
    });
};