import {useEffect} from 'react';
import {Article} from '../../../api/models/Article';
import {Case} from '../../../api/models/Case';
import DocumentService from '../../../api/services/DocumentService';

const PreviewResolver = ({history, location}: any) => {
    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const ref = params.get('token');

        if (!ref) {
            return alert(`No token available, check your configuration`);
        }

        let documentService = new DocumentService();

        documentService.getDocs({documentIds: [params.get('documentId') as string], ref})
            .then((docs: any[]) => {
                const doc = docs[0];
                if (doc.type === 'blog_article') {
                    const data = new Article(doc);
                    history.push(`/preview/blog/${data.uid}?ref=${ref}`);
                }
                if (doc.type === 'case_study') {
                    const data = new Case(doc);
                    history.push(`/preview/cases/${data.uid}?ref=${ref}`);
                }
            });
    });
    return null;
};

export default PreviewResolver;