import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './web/components/home/home';
import Article from './web/components/article-page/articlePage';
import Case from './web/components/case-page/casePage';
import Legal from './web/components/legal/legal';
import Navbar from './web/components/navbar/navbar';
import {Provider} from 'react-redux';
import store from './web/redux';
import Footer from './web/components/footer/footer';
import ArticlesBoard from "./web/components/articles-board-page/articlesBoardPage";
import CasesBoard from "./web/components/cases-board-page/casesBoardPage";
import TagsBoard from "./web/components/tags-board-page/tagsBoardPage";
import PreviewResolver from "./web/components/preview/previewResolver";
import ArticlePreview from "./web/components/article-page/articlePreview";
import NotFoundPage from "./web/components/404-not-found-page/notFoundPage";
import SnapCrawlerHelper from "./web/components/snap-crawler-helper/snapCrawlerHelper";
import CasePreview from "./web/components/case-page/casePreview";
import CookieConsent from "react-cookie-consent";
import {Link} from "react-router-dom";

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter>
                    <CookieConsent
                        buttonStyle={{height: '25px'}}
                        style={{alignItems: 'center'}}
                        buttonText="ACCEPT ALL COOKIES">By clicking “ACCEPT ALL COOKIES”, you agree LTD FIVEWALLS site can store cookies on your device and disclose information in accordance with our <Link to={'/legal/cookie-policy'} style={{textDecoration: 'underline'}}>Cookie Policy</Link>.</CookieConsent>
                    <Navbar/>

                    <Routes>

                        <Route path='*' element={<NotFoundPage />} />
                        <Route path='/404-page' element={<NotFoundPage />} />

                        <Route path="/" element={<Home />} />
                        <Route path="/cases" element={<CasesBoard />} />
                        <Route path="/cases/:uid" element={<Case />} />
                        <Route path="/blog" element={<ArticlesBoard />} />
                        <Route path="/blog/:uid" element={<Article />} />

                        <Route path="/preview" element={<PreviewResolver />}/>
                        <Route path="/preview/blog/:uid" element={<ArticlePreview />} />
                        <Route path="/preview/cases/:uid" element={<CasePreview />} />

                        <Route path="/tag/:tagName" element={<TagsBoard />} />
                        <Route path="/legal/:id" element={<Legal />} />

                        <Route path='/snap/crawler/helper/:token' element={<SnapCrawlerHelper />} />


                    </Routes>

                    <Footer/>

                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default App;
