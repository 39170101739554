import {useGqlPrismicApi} from './useGqlPrismicApi';
import CaseStudyService from '../../api/services/CaseStudyService';
import {CaseStudy} from '../../api/models/CaseStudy';


export const useGqlCaseStudyByUidApi = (query:string, ref?:string) => {
    const caseStudyService = new CaseStudyService();
    const {data, isLoading, isError} = useGqlPrismicApi<CaseStudy>({
        apiService: caseStudyService,
        query,
        ref
    })[0];

    return {data: data[0], isLoading, isError};
};