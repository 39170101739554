import React from 'react';
//* styled with parent`s scss
import styles from '../case-page.module.scss';
import {Review} from "../../../../api/models/Review";
import UserReview from "../../home/reviews/userReview/userReview";
import InViewAnimation from "../../elements/intersection-animation/intersection-animation";

interface Props {
    isLoading: boolean;
    isError: boolean;
    content: Review;
}

function ReviewSection(props: Props) {
    return (
        <section>
            <div className={styles.container}>
                <div className={`${styles.contentWrapper} ${styles.topLine}`}>
                    <div className={styles.title}>
                        <h4 className={styles.heading}>The client says</h4>
                    </div>
                    <div className={styles.content}>
                        {!props.isLoading && props.content &&
                        <InViewAnimation threshold={0.4} left>
                            <UserReview {...props.content} card={false}/>
                        </InViewAnimation>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ReviewSection;