import GqlService from './GqlService';
import {CaseStudy} from '../models/CaseStudy';

function setQuery (uid: string):string {
    return `query {
  case_study(uid: "${uid}", lang: "en-us") {
    project_name
    description
    preview_image
    hero_banner
    meta_title
    meta_description
    _meta {
      id
      uid
      type
      tags
    }
    client_review {
      ...on Review {
        review_text
        summary_text
        customer_name
        customer_photo
        company_name
        customer_position
        link_to_origin_review{
          ... on _Linkable{
            ... on _ExternalLink{
              url
            }
          }
        }
      }
    }
    next_case {
      ...on Case_study {
        project_name
        description
        hero_banner
        _meta {
          uid
        }
      }
    } 
    body{
      ...on Case_studyBodyClient{
        type
        primary{
          ...on Case_studyBodyClientPrimary{
            client_logo
            industries
            date
            text
          }
        }
      }
      ...on Case_studyBodyChallenge{
        type
        primary{
          ...on Case_studyBodyChallengePrimary{
            text
          }
        }
      }
      ...on Case_studyBodySolution{
        type
        fields{
          ...on Case_studyBodySolutionFields{
            text
            subtitle
          }
        }
      }
      ...on Case_studyBodyImage_gallery{
        type
        fields{
          ...on Case_studyBodyImage_galleryFields{
            gallery_image
          }
        }
      }
      ...on Case_studyBodyDone{
        type
        primary{
          ...on Case_studyBodyDonePrimary{
            text
          }
        }
      }        
    }
    body1{
      ...on Case_studyBody1General_card{
        type
        primary{
          ...on Case_studyBody1General_cardPrimary{
            card_title
            card_description
            image
          }
        }
      }
    }
  }
}`;
}


export default class CaseStudyService extends GqlService<CaseStudy> {

    public async index({query, ref}:{query:string, ref:string}): Promise<CaseStudy[]> {
        query = setQuery(query);
        return super.index({query, ref});
    }

    protected makeModel(data: any) {
        return new CaseStudy(data);
    }
}