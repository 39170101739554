import React, {useLayoutEffect, useState} from "react";
import styles from './navbar.module.scss';
import logo from '../../../assets/logo.svg';
import Burger from './burger/burger';
import AnimatedBtn from "../elements/button/animatedBtn/animatedBtn";
import CircleUnderlay from "./circle-underlay/circle-underlay";
import LinksContainer from "./navlinks/links-container";
import StarsAnimation from "../elements/stars-animation/stars-animation";
import {useLocation} from 'react-router-dom';


function Navbar() {

    const [isTransparent, setTransparent] = useState(false);

    const location = useLocation().pathname;
    const ref = new URLSearchParams(useLocation().search).get('token');
    const isAnimated = location === '/';
    const separated = location.split('/');

    const shouldBeTransparent = (separated[1] === 'cases' || (separated[1] === 'preview' && separated[2] === 'cases')) && separated.length >= 3;

    useLayoutEffect(() => {
        if (shouldBeTransparent) {
            setTransparent(true);
        }
        let hero = document.getElementById('hero');
        if (hero) {
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio <= 0.1) {
                        setTransparent(false);
                    } else {
                        setTransparent(true);
                    }
                });
            }, {threshold: 0.1});
            observer.observe(hero);
        }
    }, [shouldBeTransparent]);

    if (ref) {
        return (
            <nav className={`${styles.navbarWrapper} ` + (isTransparent && `${styles.transparent}`)}>
                <div className={styles.navbar}>
                    <a href={'/'} className={styles.logoContainer}><img src={logo} alt={"Company Logo"}/></a>
                    <LinksContainer/>
                    <div className={styles.menuWrapper}>
                        <div className={styles.btnWrapper}>
                            <AnimatedBtn
                                btnLabel={'Get in touch'}
                            />
                        </div>
                        <div className={styles.burgerWrapper}>
                            <Burger/>
                        </div>
                    </div>
                    <CircleUnderlay/>
                </div>
            </nav>
        );
    }

    return (
        <nav className={`${styles.navbarWrapper} ` + (isTransparent && `${styles.transparent}`)}>
            {isAnimated && <StarsAnimation/>}
            <div className={styles.navbar}>
                <a href={'/'} className={styles.logoContainer}><img src={logo} alt={"Company Logo"}/></a>
                <LinksContainer/>
                <div className={styles.menuWrapper}>
                    <div className={styles.btnWrapper}>
                        <AnimatedBtn
                            btnLabel={'Get in touch'}
                        />
                    </div>
                    <div className={styles.burgerWrapper}>
                        <Burger/>
                    </div>
                </div>
                <CircleUnderlay/>
            </div>
        </nav>
    );
}

export default Navbar;
