import React from 'react';
import styles from './hero.module.scss';
import {useLocation} from 'react-router-dom';
import {Hero} from "../../../../api/models/CaseStudy";
import ArrowLink from "../../elements/arrow-down/arrow-link";
import {imageUrlParamsHandler} from "../../../helpers/imageUrlParamsHandler";


interface Props {
    isLoading: boolean;
    isError: boolean;
    content: Hero;
}


function HeroSection(props: Props) {

    let backgroundImage = props.content ? `url(${imageUrlParamsHandler(props.content.image, {height: window.innerHeight})})` : '';

    const location = useLocation().pathname;
    const nextSection = location.slice(-1) === '/'
        ? location + '#about-client'
        : location + '/#about-client';

    return (<>

            <section id={'hero'} className={styles.caseSection} style={{backgroundImage: backgroundImage}}>
                <div className={styles.overlay}/>
                <div className={styles.container}>
                    <div className={styles.contentWrapper}>
                        {!props.isLoading && props.content &&
                        <>
                            <span>{props.content.title}</span>
                            <h1 className={styles.heading}>{props.content.description}</h1>
                        </>
                        }
                        <div className={styles.arrow}>
                            <ArrowLink toSection={nextSection}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

export default HeroSection;