import styles from './main-title.module.scss';
import React, {useEffect, useState} from 'react';
import ModalForm from "../../../elements/modal/modal";

function MainTitle() {
    const WORDINGS = ["Ask a Question", "Get a Quote", "Contact Us"];
    const TEXT_SWAP_TIMING = 2500;
    const [isVisibleIndex, setVisibleIndex] = useState(0);
    useEffect(() => {
        const timeout = setTimeout(() => setVisibleIndex(isVisibleIndex < (WORDINGS.length - 1) ? (isVisibleIndex + 1) : (WORDINGS.length - 1)), TEXT_SWAP_TIMING);
        return () => {
            clearTimeout(timeout);
        };
    });

    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <h1 className={styles.ctaContainer}>
            <span>Anytime</span>
            <span className={`${styles.cta} ${styles.cdWordsWrapper}`} onClick={openModal}>
                {WORDINGS.map((wording, index) =>
                    <b className={index === isVisibleIndex ? styles.isVisible : styles.isHidden}
                       key={index}>{wording}</b>)
                }
                <div className={styles.marker}></div>
            </span>
            {modalIsOpen && <ModalForm
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                background={'black'}
            />}
        </h1>
    );
}

export default MainTitle;
