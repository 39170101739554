import React, {useRef} from 'react';
// @ts-ignore
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from '../../elements/button/button';
import UserReview from './userReview/userReview';
import styles from './reviews.module.scss';
import {Review} from "../../../../api/models/Review";
import SkeletonReview from './userReview/skeleton-review/skeletonReview';

interface Props {
    isLoading: boolean;
    isError:boolean;
    reviews: Review[] | null
}

const sliderSettings = {
    speed: 500,
    arrows: false,
    infinite: false,
    slidesToShow: 2.6,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1.9,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1.6,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


function ReviewsSection(props:Props) {

    const sliderRef: any = useRef(null);

    function handleNext() {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    }

    function handlePrevious() {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    }

    return (
        <section className={styles.section}>
            <span>Experience</span>
            <div className={styles.mainContainer}>

                <div className={styles.titleContainer}>
                    <h1 className={styles.title}>Here's what our clients say:</h1>
                </div>
                <div className={styles.buttonsContainer}>
                    <Button className={`${styles.circleButton} ${styles.leftArrow}`}
                            onClick={handlePrevious}
                            circular={true}
                            aria-label={'previous review'}
                    />
                    <Button className={`${styles.circleButton} ${styles.rightArrow}`}
                            onClick={handleNext}
                            circular={true}
                            aria-label={'next review'}
                    />
                </div>
                <Slider ref={sliderRef} className={`${styles.reviewsContainer} reviews`} {...sliderSettings}>

                    {!props.isLoading && props.reviews &&
                    props.reviews.map((review, index) =>
                        <UserReview
                            key={index}
                            {...review}
                            card={true}
                        />)
                    }

                    {props.isLoading && Array(10)
                        .fill('', 0)
                        .map((item, index) =>
                            <SkeletonReview key={index}/>
                        )}

                    <div className={styles.heightHolder} ><SkeletonReview /></div>
                </Slider>
            </div>
        </section>
    );
}

export default ReviewsSection;
