import React, {useEffect, useState} from 'react';
import {InView} from 'react-intersection-observer';
import styles from './intersection-animation.module.scss';

interface Props {
    children: any;
    threshold: number;
    right?: boolean;
    left?: boolean;
    skip?: boolean;
}

export default function InViewAnimation(props: Props) {

    const initClass = props.right
        ? `${styles.right}`
        : props.left
            ? `${styles.left}`
            : `${styles.bottom}`;

    const resetClass = props.right || props.left ? ` ${styles.resetX}` : ` ${styles.resetY}`;

    const [inViewClass, setInViewClass] = useState(initClass);

    const handleClass = (triggered: boolean) => triggered && setInViewClass(inViewClass + resetClass);

    useEffect(() => {
        return () => setInViewClass(initClass);
    }, [props.children]);

    return (
        <InView as='div'
                className={props.skip ? resetClass : `${styles.init} ` + inViewClass}
                threshold={props.threshold}
                skip={props.skip}
                onChange={(inView) => handleClass(inView)}
        >
            {props.children}
        </InView>
    );
};
