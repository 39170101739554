import styles from './main.module.scss';
import React from 'react';
import MainTitle from "./main-title/main-title";
import ArrowLink from "../../elements/arrow-down/arrow-link";
import LookForUs from "./look-for-us/lookForUs";
import StarsAnimation from "../../elements/stars-animation/stars-animation";

function Main() {

    return (
        <section className={styles.section}>
            <div className={styles.wave}>
                <StarsAnimation />
            </div>
            <div className={styles.titleWrapper}>
                <MainTitle/>
                <div className={styles.paragraphWrapper}>
                    <p>Your remote Software Development Team that feels like in-house.</p>
                    <p>Stable, reliable and fast.</p>
                </div>
            </div>
            <div className={styles.lookForUsWrapper}>
                <LookForUs/>
                <ArrowLink toSection={'/#aboutUs'}/>
            </div>
        </section>
    );
}

export default Main;
