import {Reducer} from 'redux';
import {menuActions} from '../actions/actionsNavmenu';

export interface NavmenuState {
    isOpen: boolean
}

export const initialState: NavmenuState = {
    isOpen: false
};

const reducer: Reducer<NavmenuState, menuActions> = (state = initialState, action) => {
    if (action.type === 'OPEN_MENU') {
        return {
            isOpen: true
        };
    }

    if (action.type === 'CLOSE_MENU') {
        return {
            isOpen: false
        };
    }

    return state;
};

export {reducer as navmenuReducer};