import React from 'react';
import styles from './article-body.module.scss';
import {RichText} from 'prismic-reactjs';
import TagsBlock from '../../elements/tags-block/tagsBlock';
import CtaButton from "../../elements/button/cta-button/ctaButton";
import {Article} from "../../../../api/models/Article";
import SkeletonBody from "./skeleton-body/skeletonBody";

interface Props {
    data: Article,
    isLoading: boolean,
    isError: boolean
}


function ArticleBody(props:Props) {

    return (
        <article className="white-background">
            <div className={styles.articleContainer}>

                {props.isLoading && <SkeletonBody/>}

                {!props.isLoading && <>
                    <RichText render={props.data.content[0].title1 as any}/>
                    {props.data.hasTags && <TagsBlock styledForArticle={true} tags={props.data.tags}/>}
                    {props.data.content.map((textGroup: any, index: number) =>
                        <div key={index}>
                            <RichText render={textGroup.text}/>
                            {textGroup.show_cta && <CtaButton
                                position={textGroup.position_cta}
                                text_line_1={textGroup.cta_text_line_1}
                                text_line_2={textGroup.cta_text_line_2}
                                background_color={textGroup.cta_background_color}
                                divider_color={textGroup.cta_divider_color}
                                text_color={textGroup.cta_text_color}
                            />}
                        </div>
                    )}
                </>}

            </div>
        </article>
    );
}

export default ArticleBody;
