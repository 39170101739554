import {useEffect, useState} from "react";
import DocumentService, {DocRequestParams} from '../../api/services/DocumentService';
import {Document} from '../../api/models/Document';

export const usePrismicApi = <Model>({apiService, params}: { apiService: DocumentService<Document>, params: DocRequestParams }) => {

    const [data, setData] = useState<Model[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const url = new URL(window.location.href);
            if (url.searchParams.get('page')) {
                params.page = url.searchParams.get('page') as any;
            }
            try {
                const result = await apiService.index(params);
                setData(result);
            } catch (error) {
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [params.pageSize, params.page]);

    return [{ data, isLoading, isError }, ];

};
