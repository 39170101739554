export function imageUrlParamsHandler(imgUrl: string, {width, height}:{width?:number, height?:number}): string {
    const url = new URL(imgUrl);
    if (width) {
        url.searchParams.set('w', `${width}` || '640');
    }
    if (height) {
        url.searchParams.set('h', `${height}`);
    }
    return url.href;
}
