import React from 'react';
import styles from './tags-block.module.scss';
import {NavLink} from "react-router-dom";

interface TagsBlockProps {
    tags: string[];
    styledForArticle?: boolean;
}

function TagsBlock(props: TagsBlockProps) {
    const tagClass = props.styledForArticle ? styles.articleStyleTag : styles.caseStyleTag;
    return (
        <div className={props.styledForArticle ? styles.container : ''}>
            {props.tags.map(tag =>
                <NavLink
                    to={`/tag/${tag}`}
                    className={`${styles.tag} ${tagClass}`}
                    key={tag}
                >
                    {tag}
                </NavLink>
            )}
        </div>
    );
}

export default TagsBlock;
