import React, {useState} from 'react';
import styles from './footer.module.scss';
import ModalForm from "../elements/modal/modal";
import {Link} from "react-router-dom";
import {useContactsApi} from "../../hooks/useContactsApi";
import ContactsSkeleton from './contacts-skeleton/contactsSkeleton';

function Footer() {
    const currentYear = (new Date()).getFullYear();
    const [modalIsOpen, setIsOpen] = useState(false);

    let [{data: contacts, isLoading, isError}] = useContactsApi();
    const contact = contacts[0] || {};

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <section id="contactsSection" className={`${styles.section} white-background`}>
            <span>Get in touch</span>
            <h1 className={styles.title}>
                Yes, we are&nbsp;
                <div onClick={openModal} className={styles.link}>
                        <span className={styles.wordsWrapper}>
                            <b className={''}>ready to discuss</b>
                        </span>
                </div>
                {modalIsOpen && <ModalForm
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    background={'white'}
                />}
                <div>your idea</div>
            </h1>

            {isLoading && <ContactsSkeleton/>}
            {!isLoading &&
            <div className={styles.container}>
                <div className={styles.contacts}>
                    <p>{contact.email}</p>
                    <p>{contact.phone}</p>
                </div>
                <div className={styles.address}>
                    <p className={styles.addressText}>{contact.address}</p>
                </div>
            </div>
            }

            <div className={`${styles.footerContainer} small`}>
                <div className={styles.policyContainer}>
                    <Link to={'/legal/privacy-policy'} className={`${styles.policyLink} ${styles.grey}`}>Privacy
                        Policy</Link>
                    <Link to={'/legal/cookie-policy'} className={`${styles.policyLink} ${styles.grey}`}>Cookie
                        Policy</Link>
                    <Link to={'/legal/terms-and-conditions'} className={`${styles.policyLink} ${styles.grey}`}>Terms and conditions</Link>
                    <Link to={'/legal/terms-of-service'} className={styles.grey}>Terms of Service</Link>
                </div>
                <div className={styles.copyright}>
                    <p className={`${styles.grey} small mt0 mb0`}>© Copyright {currentYear}. All rights reserved</p>
                </div>
            </div>
        </section>
    );
}

export default Footer;
