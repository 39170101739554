import React, {useState} from 'react';
import styles from './cta-button.module.scss';
import Button from '../button';
import ModalForm from "../../modal/modal";

interface Props {
    text_line_1: string;
    text_line_2: string;
    position?: string;
    background_color?: string;
    divider_color?: string;
    text_color?: string;
}

function CtaButton(props: Props) {

    const position = props.position === 'left'
        ? 'row-reverse'
        : props.position === 'right'
            ? 'row' : 'column';

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <div className={styles.ctaContainer}
                 style={{
                     background: props.background_color || "linear-gradient(to right bottom, #ffae00, #ff9300, #ff7500, #ff5200, #ff1717)",
                     flexDirection: position
                 }}
                 onClick={openModal}
            >
                <div className={styles.innerCtaContainer}>
                <div
                    className={styles.textWrapper}
                    style={{color: props.text_color || "#f7f7f7"}}
                >
                    <div>{props.text_line_1}</div>
                    <hr
                        className={styles.divider}
                        style={{backgroundColor: props.divider_color || "#f7f7f7"}}
                    />
                    <div>{props.text_line_2}</div>
                </div>
                <div className={styles.btnWrapper}>
                    <Button
                        className={styles.btn}
                        style={{
                            borderColor: props.divider_color || "#f7f7f7"
                        }}
                    >GET IN TOUCH</Button>
                </div>
                </div>
            </div>

            {modalIsOpen && <ModalForm
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                background={'white'}
            />}
        </>
    );
}

export default CtaButton;