import {Document} from './Document';

export interface ArticleContent {
    title1: object
}

export class Article extends Document {
    public uid: string;
    public date: number;
    public tags: string[];
    public title: string;
    public meta_title: string;
    public meta_description: string;
    public preview_image: string;
    public preview_image_alt: string;
    public preview_text: string;
    public meta_og_title: string;
    public meta_og_description: string;
    public meta_og_image: string;
    public content: ArticleContent[];
    public hasTags: boolean;

    constructor(doc: any) {
        super(doc);
        this.uid = doc.uid;
        this.date = doc.last_publication_date;
        this.tags = doc.tags;
        this.title = doc.data?.title;
        this.meta_title = doc.data?.meta_title;
        this.meta_description = doc.data?.meta_description;
        this.preview_image = doc.data?.preview_image?.url;
        this.preview_image_alt = doc.data?.preview_image?.alt;
        this.preview_text = doc.data?.description;
        this.meta_og_title = doc.data?.body?.[0]?.primary?.card_title[0]?.text;
        this.meta_og_description = doc.data?.body?.[0]?.primary?.card_description[0]?.text;
        this.meta_og_image = doc.data?.body?.[0]?.primary?.image.url;
        this.content = doc.data?.article;
        this.hasTags = !!doc.tags?.length;
    }
}
