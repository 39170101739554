import {Store, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {ApplicationState, createRootReducer} from './reducers';

function configureStore(): Store<ApplicationState> {
    const composeEnhancers = composeWithDevTools({});
    const store = createStore(
        createRootReducer(),
        composeEnhancers()
    );
    return store;
}

const store = configureStore();

export default store;