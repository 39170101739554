import {usePrismicApi} from './usePrismicApi';
import ArticleCaseService from '../../api/services/ArticleCaseService';
import {Article} from '../../api/models/Article';
import {Case} from '../../api/models/Case';

export const useArticlesCasesByTagApi = ({queryBy = 'document.tags', tag = '', pageSize = 10, page = 1} = {}) => {
    const articleCaseService = new ArticleCaseService();

    return usePrismicApi<Article|Case>({
        apiService: articleCaseService,
        params: {queryBy, value: [tag], pageSize, page}
    });
};