import DocumentService from './DocumentService';
import {Article} from '../models/Article';

export default class ArticleService extends DocumentService<Article> {

    public async index(params: object): Promise<Article[]> {
        return super.index({...params});
    }

    protected makeModel(doc: any) {
        return new Article(doc);
    }
}