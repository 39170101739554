import React from 'react';
import styles from '../article-body.module.scss';
import Skeleton from "react-loading-skeleton";

function SkeletonBody() {

    return (
        <div className={styles.skeletonContainer}>
            <div className={styles.skeletonHeading}><Skeleton count={2}/></div>
            <div className={styles.skeletonTags}><Skeleton/></div>
            <div className={styles.skeletonImg}><Skeleton/></div>
            <div className={styles.skeletonSubheading}><Skeleton/></div>
            <div className={styles.skeletonParagraph}><Skeleton count={4}/></div>
            <div className={styles.skeletonParagraph}><Skeleton count={4}/></div>
            <div className={styles.skeletonParagraph}><Skeleton count={4}/></div>
            <div className={styles.skeletonSubheading}><Skeleton/></div>
            <div className={styles.skeletonParagraph}><Skeleton count={10}/></div>
            <div className={styles.skeletonSubheading}><Skeleton/></div>
            <div className={styles.skeletonParagraph}><Skeleton count={3}/></div>
            <div className={styles.skeletonParagraph}><Skeleton count={5}/></div>
            <div className={styles.skeletonParagraph}><Skeleton count={4}/></div>
        </div>
    );
}

export default SkeletonBody;
