import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import ArticleBody from "./article-body/articleBody";
import {useArticleByUidApi} from '../../hooks/useArticleByUidApi';
import useMetaTags from '../../hooks/useMetaTags';

function ArticlePreview({location}:any) {
    const {uid: value} = useParams() as any;
    const params = new URLSearchParams(location.search);

    const {data: article, isLoading, isError} = useArticleByUidApi({ section: 'blog_article', value, ref: params.get('ref') as string });
    const fetchState = {isLoading, isError};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [value]);

    useMetaTags(article);


    return (
        <ArticleBody {...fetchState} data={article}/>
    );
}

export default ArticlePreview;
