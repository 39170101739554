import {usePrismicApi} from './usePrismicApi';
import ArticleService from '../../api/services/ArticleService';
import {Article} from '../../api/models/Article';

export const useArticlesApi = ({queryBy = 'document.type', value = 'blog_article', pageSize = 10, page = 1} = {}) => {
    const articleService = new ArticleService();

    return usePrismicApi<Article>({
        apiService: articleService,
        params: {queryBy, value, pageSize, page}
    });
};
