import React, {ReactNode} from 'react';

interface Button extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    circular?: boolean,
    children?: ReactNode,
}

function Button(props: Button) {

    const {
        circular,
        children,
        ...rest
    } = props;


    return (
        <button {...rest}>
            {props.children}
        </button>
    );
}

export default Button;
