import {Basic} from "./Basic";
import {Review} from "./Review";
import {Case} from "./Case";

export class ClientInfo extends Basic {
    public logo: string;
    public date: string;
    public industries: string;
    public text: object[];

    constructor(doc: any) {
        super(doc);
        this.logo = doc.primary.client_logo.url;
        this.date = doc.primary.date;
        this.industries = doc.primary.industries;
        this.text = doc.primary.text;
    }
}

export class Hero extends Basic {
    public image: string;
    public title: string;
    public description: string;
    public uid?: string;

    constructor(doc: any) {
        super(doc);
        this.image = doc.image;
        this.title = doc.title;
        this.description = doc.preview_text;
        this.uid = doc?.uid;
    }
}

export class CaseStudy extends Case {

    public hero: Hero;
    public client_review?: Review;
    public client_info?: ClientInfo;
    public challenge_section?: object[];
    public solution_section?: object[];
    public image_gallery?: object[];
    public done_section?: object[];
    public next_case?: Hero;
    public hasTags?: boolean;
    public tags?: string[];


    constructor(doc: any) {

        doc = doc.case_study;

        super(doc);

        this.hero = new Hero({
            image: doc.hero_banner.url,
            title: this.title,
            preview_text: this.preview_text
        });
        if (doc.client_review) {
            this.client_review = new Review({data: {...doc.client_review}});
        }

        if (doc.body) {
            this.client_info = new ClientInfo(doc.body.filter((section: any) => section.type === 'client')?.[0]) || null;
            this.challenge_section = doc.body.filter((section: any) => section.type === 'challenge')?.[0]?.primary.text;
            this.solution_section = doc.body.filter((section: any) => section.type === 'solution')?.[0]?.fields;
            this.done_section = doc.body.filter((section: any) => section.type === 'done')?.[0]?.primary.text;
            this.image_gallery = doc.body
                .filter((section: any) => section.type === 'image_gallery')?.[0]?.fields
                .map((field: any) => field.gallery_image);
        }

        if (doc.next_case) {
            this.next_case = new Hero({
                image: doc.next_case.hero_banner.url,
                title: doc.next_case.project_name,
                preview_text: doc.next_case.description,
                uid: doc.next_case._meta.uid
            });
        }
        this.tags = doc._meta?.tags;
        this.hasTags = !!doc._meta?.tags?.length;
    }
}
