import {usePrismicApi} from './usePrismicApi';
import LegalService from '../../api/services/LegalService';
import {Legal} from '../../api/models/Legal';

export const useLegalApi = () => {
    const legalService = new LegalService();

    return usePrismicApi<Legal>({
        apiService: legalService,
        params: { }
    });
};