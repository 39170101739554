import {combineReducers} from 'redux';
import {NavmenuState, navmenuReducer} from "./nav-menu-reducer";

// The top-level state object
export interface ApplicationState {
    navmenu: NavmenuState,

}

export function createRootReducer() {
    return combineReducers({
        navmenu: navmenuReducer,
    });
}
