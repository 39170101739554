import React, {useState} from 'react';
import styles from './animated-btn.module.scss';
import Button from '../button';
import ModalForm from "../../modal/modal";


interface Props {
    btnLabel: string;
}

function AnimatedBtn(props: Props) {

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <Button
                className={styles.btn}
                onClick={openModal}
            >
                {props.btnLabel}
                <span
                    className={`${styles.dot} ${styles.pulseAnimation}`}/>
            </Button>
            {modalIsOpen && <ModalForm
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                background={'black'}
            />}
        </>
    );
}

export default AnimatedBtn;