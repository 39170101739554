import {useGqlPrismicApi} from './useGqlPrismicApi';
import HomepageContentService from '../../api/services/HomepageContentService';
import {HomepageContent} from '../../api/models/HomepageContent';

export const useHomepageContentApi = () => {
    const homepageContentService = new HomepageContentService();

    return useGqlPrismicApi<HomepageContent>({
        apiService: homepageContentService
    });
};