import {usePrismicApi} from './usePrismicApi';
import ArticleService from '../../api/services/ArticleService';
import {Article} from '../../api/models/Article';

export const useArticleByUidApi = ({ section = 'blog_article', value = '', ref }: { section?: string, value?: string, ref?: string }) => {
    const articleService = new ArticleService();
    const queryBy = `my.${section}.uid`;
    const {data, isLoading, isError} = usePrismicApi<Article>({
            apiService: articleService,
            params: { queryBy, value, ref }
        })[0];

    return {data: data[0], isLoading, isError};
};
