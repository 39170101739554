import React, {useEffect, useState} from 'react';
import {useArticlesApi} from "../../hooks/useArticlesApi";
import {Article} from "../../../api/models/Article";
import BoardBlock from "../elements/board-block/boardBlock";
import useMetaTags from "../../hooks/useMetaTags";


function ArticlesBoard() {

    const [content, setContent] = useState<Article[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const [{data: articles, isLoading, isError}] = useArticlesApi({page});

    useEffect(() => {
        articles && setContent([...content, ...articles]);
        return () => {
            setContent([]);
        };
    }, [articles]);

    useEffect(() => {
        if (articles && content.length % 10 !== 0) {
            setHasMore(false);
        }
    });

    useMetaTags();

    return (
        <BoardBlock
            content={content}
            hasMore={hasMore}
            currentPage={page}
            setPage={() => setPage(page + 1)}
            isLoading={isLoading}
            blockTitle={'Blog'}
        />
    );
}

export default ArticlesBoard;