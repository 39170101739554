import {usePrismicApi} from './usePrismicApi';
import ContactService from '../../api/services/ContactService';
import {Contact} from '../../api/models/Contact';

export const useContactsApi = () => {
    const contactService = new ContactService();

    return usePrismicApi<Contact>({
        apiService: contactService,
        params: { }
    });
};