import React, {useState} from 'react';
import FormBody from "./form-body/formBody";
import styles from "./contact-form.module.scss";
import FormMessage from "./form-message/formMessage";

interface Props {
    onCloseModal: ()=>void
}

function ContactForm(props:Props) {

    const [formState, setFormState] = useState({showMessage: false, isSuccess: true});

    const handleMsgBtnClick = () => {
        setFormState({...formState, showMessage: false});
    };

    const handleResponse = (isSuccessResponse: boolean) => {
        if (isSuccessResponse) {
            // @ts-ignore
            window.gtag_report_conversion();
        }
        setFormState(
            isSuccessResponse
                ? {showMessage: true, isSuccess: true}
                : {showMessage: true, isSuccess: false}
        );
    };

    return (
        <div className={styles.formContainer}>
            {!formState.showMessage && <FormBody onClose={props.onCloseModal} onResponse={handleResponse}/>}
            {formState.showMessage &&
            <FormMessage isSuccess={formState.isSuccess} onBtnClick={handleMsgBtnClick}/>}
        </div>
    );
}

export default ContactForm;
