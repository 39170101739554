import Prismic from 'prismic-javascript';
import {ApiOptions} from 'prismic-javascript/types/Api';
import {DefaultClient} from 'prismic-javascript/types/client';
import {Document} from '../models/Document';
import IPrismicApiService from "./IPrismicApiService";

const apiEndpoint: any = process.env.REACT_APP_CMS_PRISMIC_API_URL;


export interface DocRequestParams {
    queryBy?: string,
    value?: string | string[],
    pageSize?: number,
    page?: number,
    ref?: string,
}

export default class DocumentService<Model extends Document> implements IPrismicApiService<Model> {

    protected client: DefaultClient;

    constructor(options?: ApiOptions) {
        this.client = Prismic.client(apiEndpoint, options);
    }

    public async index({
                           queryBy = 'document.type',
                           value = 'case_study',
                           pageSize = 100,
                           page = 1,
                           ref
                       }: DocRequestParams = {}): Promise<any> {

        const queryOptions: any = {
            orderings: '[document.last_publication_date desc]',
            pageSize,
            page
        };

        if (ref) {
            queryOptions.ref = ref;
        }

        const docs = await this.client.query(Prismic.Predicates.at(queryBy, value), queryOptions);
        return docs.results.map((doc: object) => this.makeModel(doc));
    }

    public async getDocs({documentIds = [], ref}: { documentIds: string[], ref?: string }) {
        const queryOptions: { ref?: any } = {};

        if (ref) {
            queryOptions.ref = ref;
        }

        const docs = await this.client.query([
            Prismic.Predicates.any('document.id', documentIds)
        ], queryOptions);

        return docs.results;
    }

    protected makeModel(data: any) {
        return new Document(data) as Model;
    }
}
