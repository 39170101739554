const OPEN_MENU = 'OPEN_MENU';
type OPEN_MENU = typeof OPEN_MENU;
const CLOSE_MENU = 'CLOSE_MENU';
type CLOSE_MENU = typeof CLOSE_MENU;

interface OpenMenu {
    type: OPEN_MENU
}

interface CloseMenu {
    type: CLOSE_MENU
}

export function actionOpenMenu(): OpenMenu {
    return {type: OPEN_MENU};
}

export function actionCloseMenu(): CloseMenu {
    return {type: CLOSE_MENU};
}

export type menuActions = OpenMenu | CloseMenu